body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .wallet-multi-button {
  marginTop: '20px';
        marginBottom: '20px';
        marginRight: '20px';
        padding: '20px';
        position: 'fixed';
        top: '0';
        right: '0';
        color: 'black';
        backgroundColor: 'white';
        border: '2px solid black';
        borderRadius: '10px';
        zIndex: '1000';
        cursor: 'pointer';
        font-family: 'Micro 5', sans-serif;

        &:hover: {
            backgroundColor: '#ddd',
        }

    } */
